import React from 'react';

import PrivateLayout from "layouts/material-private";
import { useHistory, useParams } from 'react-router-dom';
import useQuery from '@lottoasean/query-hook';
import useMutation from '@lottoasean/mutation-hook';
import isEmpty from 'lodash.isempty';
import VarietyForm from 'components/forms/VarietyForm';
import { fetchVariety, updateVariety } from 'apiRequest/variety';
import FormContainer from 'components/FormContainer';
import updateVarietyValidation from 'formValidations/updateVariety';

export default function VarietyUpdate() {
  const { id } = useParams();

  const history = useHistory();
  const { loading, error, payload: variety = { data: {} } } = useQuery(fetchVariety, { id, params: { partials: ['lotto'] } });
  const { loading: mutateLoading, error: mutateError, mutate } = useMutation(updateVariety);

  const handleOnSubmit = async (data) => {

    const submitData = {
      ...data,
    };

    const { error: mutateError } = await mutate({ id, data: submitData });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      history.push(`/lottos/${variety.data.lotto?.id}`);
    }
  };

  return (
    <PrivateLayout loading={loading} error={error}>
      {!isEmpty(variety.data) &&
        <FormContainer
          initialValues={variety.data}
          title="Edit Variety"
          subheader={`${variety.data.lotto?.title} ${variety.data?.title}`}
          onSubmit={handleOnSubmit}
          validationSchema={updateVarietyValidation}
          serverError={mutateError}
        >
          <VarietyForm loading={mutateLoading} />
        </FormContainer>
      }
    </PrivateLayout>
  );
}
