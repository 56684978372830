import fetch from "@lottoasean/fetch-utils";

export const fetchUser = ({ token, params }) => {
  return fetch({
    url: 'user',
    method: "GET",
    params,
    token
  });
};

export const logoutUser = ({ token }) => {
  return fetch({
    url: 'user/logout',
    method: "POST",
    token
  });
};
