import { Table, TableBody, TableContainer, TableRow, TableCell, TableHead, TablePagination, Toolbar, Typography, Card, Box, TableSortLabel, Container } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import currency from '@lottoasean/currency-utils';
import SearchBox from './SearchBox';
import TableLoading from './TableLoading';
import ToolbarActions from './ToolbarActions';

export default function ISTable({
  rowKey = 'id',
  loading,
  data,
  columns,
  title,
  subheader,
  count,
  page,
  rowsPerPage,
  onPageChange,
  size = 'small',
  maxWidth = "none",
  pagination,
  filter,
  FilterFields,
  filterFieldsProps,
  filterFields,
  filterSubmit,
  download,
  onDownloadClick,
  downloadLoading,
  search,
  searchFields,
  searchSubmit,
  sortClick,
  defaultSort = {
    key: 'id',
    order: 'desc',
  }
}) {

  const [order, setOrder] = useState(defaultSort.order);
  const [orderBy, setOrderBy] = useState(defaultSort.key);

  const handlePageChange = (e, page) => onPageChange(Number(page + 1));
  const handleTableSortClick = (key) => () => {
    const newOrder = order === 'asc' ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(key);
    sortClick({ key, order: newOrder });
  };

  return (
    <Container maxWidth={maxWidth}>
      <Toolbar sx={{ pl: { xs: 0 } }}>
        <Box sx={{ flex: '1' }}>
          <Typography variant="h5">
            {title}
          </Typography>
          {subheader &&
            <Typography variant="body1" sx={{ mb: 1 }}>
              {subheader}
            </Typography>
          }
        </Box>
        <ToolbarActions
          filter={filter}
          FilterFields={FilterFields}
          filterFields={filterFields}
          filterFieldsProps={filterFieldsProps}
          filterSubmit={filterSubmit}

          download={download}
          onDownloadClick={onDownloadClick}
          downloadLoading={downloadLoading}
        />
      </Toolbar>

      {search && <SearchBox searchSubmit={searchSubmit} searchFields={searchFields} />}

      <TableContainer component={Card} sx={{ p: 2, position: "relative" }}>
        <TableLoading loading={loading} />
        <Table size={size}>
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <TableCell key={column.key}>
                    {column.sort === true ? (
                      <TableSortLabel
                        active={orderBy === column.key}
                        direction={order || 'desc'}
                        onClick={handleTableSortClick(column.key)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : column.label
                    }
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody sx={{ position: "relative" }}>
            {data?.map((row) => {
              return (
                <TableRow key={row[rowKey]}>
                  {columns.map((column) => {
                    const value = column.selector(row);
                    const valueSign = Math.sign(value);
                    let color = "text.main";
                    if (column.highlight && valueSign === -1) color = "error.main";
                    if (column.highlight && valueSign === 1) color = "success.main";
                    return (
                      <TableCell key={column.key}>
                        <Typography variant="span" color={color}>
                          {column.currency ? currency.format(value) : value}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && !loading && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handlePageChange}
        />
      )}
    </Container>
  );
}
