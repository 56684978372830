import React from 'react';

import PrivateLayout from "layouts/material-private";
import { useHistory, useParams } from 'react-router-dom';
import useQuery from '@lottoasean/query-hook';
import useMutation from '@lottoasean/mutation-hook';
import isEmpty from 'lodash.isempty';
import { fetchNumber, updateNumber } from 'apiRequest/number';
import NumberForm from 'components/forms/NumberForm';
import FormContainer from 'components/FormContainer';
import updateNumberValidation from "formValidations/updateNumber"

export default function VarietyNumberUpdate() {
  const { varietyId, numberId } = useParams();

  const history = useHistory();
  const { loading, error, payload: number = { data: {} } } = useQuery(fetchNumber, { id: numberId, params: { partials: ['variety', 'variety.lotto'] } });
  const { loading: mutateLoading, error: mutateError, mutate } = useMutation(updateNumber);

  const handleOnSubmit = async (data) => {
    const submitData = {
      ...data,
      variety_id: varietyId,
    };

    const { error: mutateError } = await mutate({ id: numberId, data: submitData });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      history.push(`/varieties/${varietyId}`);
    }
  };

  return (
    <PrivateLayout loading={loading} error={error}>
      {!isEmpty(number.data) &&
        <FormContainer
          initialValues={number.data}
          title={`Edit Number ${number.data.number}`}
          subheader={`${number.data.variety?.lotto.title} ${number.data.variety?.title}`}
          onSubmit={handleOnSubmit}
          validationSchema={updateNumberValidation}
          serverError={mutateError}
        >
          <NumberForm loading={mutateLoading} />
        </FormContainer>
      }
    </PrivateLayout >
  );
}
