import React from 'react';

import PrivateLayout from "layouts/material-private";
import { fetchWins } from "apiRequest/wins";
import { Link as RouterLink } from 'react-router-dom';
import useQuery from '@lottoasean/query-hook';
import ISTable from 'components/lSTable';
import { Link } from '@material-ui/core';

export default function WinList() {

  const { loading, error, payload: wins, query } = useQuery(fetchWins, {
    params: { filters: { paid: false }, partials: ['variety', 'variety.lotto', 'round'] }
  });

  const handlePageChange = (page) => {
    query({ params: { page } });
  };

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.id,
    },
    {
      key: 'lotto',
      label: 'Lotto',
      selector: row => row.variety.lotto.title,
    },
    {
      key: 'variety',
      label: 'Variety',
      selector: row => row.variety.title,
    },
    {
      key: 'round',
      label: 'Round',
      selector: row => (
        <Link variant="contained" component={RouterLink} to={`/rounds/${row.round_id}/wins`} >
          {row.round.title}
        </Link>
      ),
    },
  ], []);

  return (
    <PrivateLayout loading={loading} error={error}>
      <ISTable
        maxWidth="lg"
        size="medium"
        title="Unpiad Wins"
        loading={loading}
        columns={columns}
        data={wins?.data}
        pagination={true}
        count={wins?.total}
        page={wins?.current_page}
        rowsPerPage={wins?.per_page}
        onPageChange={handlePageChange}
      />
    </PrivateLayout>
  );
}
