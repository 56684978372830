import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';

export default function TableLoading({ loading }) {
  return (
    <Backdrop
      sx={{ position: "absolute" }}
      open={loading}
    >
      <CircularProgress />
    </Backdrop>
  );
}
