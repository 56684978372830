import React from 'react';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography
} from '@material-ui/core';
import { cyan } from '@material-ui/core/colors';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

export default function TotalBet({ loading, data }) {
  return (
    <Card>
      <CardContent>
        <Grid
          container
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              TOTAL BETS
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {loading ? <Skeleton /> : data?.total}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: cyan[600],
                height: 56,
                width: 56
              }}
            >
              <ConfirmationNumberIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
