import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PrivateLayout from "layouts/material-private";
import { fetchLottos } from "apiRequest/lotto";
import useQuery from '@lottoasean/query-hook';
import ISTable from 'components/lSTable';
import { Link } from '@material-ui/core';

export default function LottoList() {
  const { loading, error, payload: lottos = { data: [] } } = useQuery(fetchLottos);

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => <Link component={RouterLink} to={`/lottos/${row.id}`}>{row.id}</Link>
    },
    {
      key: 'title',
      label: 'Title',
      selector: row => row.title,
    }
  ], []);

  return (
    <PrivateLayout loading={loading} error={error}>
      <ISTable
        title="Lottos"
        maxWidth="md"
        loading={loading}
        columns={columns}
        data={lottos.data}
        size="medium"
      />
    </PrivateLayout>
  );
}
