import React from 'react';

import PrivateLayout from "layouts/material-private";
import { fetchBet } from "apiRequest/bet";
import { useParams } from 'react-router-dom';
import currency from '@lottoasean/currency-utils';
import dateTime from '@lottoasean/datetime-utils';
import { sumBy } from 'lodash';
import useQuery from '@lottoasean/query-hook';
import ISTable from 'components/lSTable';

export default function BetShow() {
  const { id } = useParams();

  const { loading, error, payload: bet = { data: {} } } = useQuery(fetchBet, { id, params: { partials: ['member', 'bet_numbers', 'bet_numbers.number'] } });
  const totalBetUnit = sumBy(bet.data.bet_numbers, (b) => b.unit);

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.id
    },
    {
      key: 'number',
      label: 'Number',
      selector: row => row.number.number
    },
    {
      key: 'unit',
      label: 'Unit',
      selector: row => currency.format(row.unit)
    },
    {
      key: 'multiply',
      label: 'Multiply',
      selector: row => row.multiply
    },
    {
      key: 'win_rate',
      label: 'Win Rate',
      selector: row => currency.format(row.unit * row.multiply)
    },
  ], []);

  return (
    <PrivateLayout loading={loading} error={error}>
      <ISTable
        title={`#${bet.data.id} ${bet.data.member?.name} (${dateTime.format(bet.data.created_at)})`}
        subheader={`Total: ${currency.format(totalBetUnit)}`}
        maxWidth="md"
        loading={loading}
        columns={columns}
        data={bet.data.bet_numbers}
      />
    </PrivateLayout>
  );
}
