import { Typography } from '@material-ui/core';
import { useFieldApi } from 'informed';
import React from 'react';

export default function FieldErrorMessage({ field }) {
  const fieldApi = useFieldApi(field);

  return (
    <Typography color="error" fontSize="small">{fieldApi.exists() && fieldApi.getError()}</Typography>
  );
}
