import { useCallback, useEffect, useState } from "react";
import merge from "lodash.merge";
import useToken from "@lottoasean/token-hook";

export default function useQuery(action, specification) {
    const { token } = useToken();

    const [payload, setPayload] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    const handleQuery = useCallback(
        (newSpecification) => {
            setLoading(true);

            action({ token, ...merge(specification, newSpecification) }).then(({ data }) => {
                setLoading(false);
                setPayload(data);
            }).catch(({ response }) => {
                setLoading(false);
                setError(response);
            });
        },
        [token, action] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
        handleQuery({});
    }, [handleQuery]);

    return {
        loading, error, payload, query: handleQuery
    };
}
