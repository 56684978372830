import { Logout as LogoutIcon } from '@material-ui/icons';
import React from 'react';
import logo from 'logo.svg';
import { NavLink, useHistory } from 'react-router-dom';

import { AppBar, Box, IconButton, Toolbar, CircularProgress } from '@material-ui/core';
import useToken from '@lottoasean/token-hook';
import useMutation from '@lottoasean/mutation-hook';
import { logoutUser } from 'apiRequest/user';

export default function NavBar() {
  const { removeToken } = useToken();
  const history = useHistory();
  const { loading: logoutUserLoading, mutate } = useMutation(logoutUser);

  const handleLogout = async () => {
    const { error } = await mutate();
    if (error) {
      console.log(error);
      alert("something was wrong");
    } else {
      removeToken();
      history.push('/login');
    }
  };

  return (
    <AppBar>
      <Toolbar>
        <NavLink to="/">
          <img src={logo} height="56" alt="Lotto ASEAN" />
        </NavLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ position: 'relative' }}>
          <IconButton color="inherit" onClick={handleLogout} disabled={logoutUserLoading}>
            <LogoutIcon />
          </IconButton>
          {logoutUserLoading && <CircularProgress
            sx={{
              position: 'absolute',
              left: -3,
              zIndex: 1,
            }}
          />}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
