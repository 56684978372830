import { Divider, } from '@material-ui/core';
import React from 'react';
import TextInput from './fields/TextInput';
import SubmitButton from './fields/SubmitButton';

export default function MemberForm({ loading }) {
  return (
    <>
      <TextInput field="name" label="Name" />
      <TextInput field="username" label="Username" />
      <TextInput field="phone_number" label="Phone Number" />
      <TextInput field="password" label="Password" />
      <Divider />
      <SubmitButton loading={loading}>Submit</SubmitButton>
    </>
  );
}
