import { FormControl, FormHelperText } from '@material-ui/core';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { useField } from 'informed';
import React from 'react';

export default function MDEditorTextarea(props) {
  const { fieldState, fieldApi, ref, userProps } = useField(props);
  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, ...rest } = userProps;
  return (
    <FormControl fullWidth margin="dense" error={Boolean(fieldState.error)}>
      <MDEditor
        {...rest}
        ref={ref}
        value={!value && value !== 0 ? '' : value}
        onChange={value => {
          setValue(value);
          if (onChange) {
            onChange(value);
          }
        }}
        onBlur={e => {
          setTouched(true);
          if (onBlur) {
            onBlur(e);
          }
        }}
        commands={[
          commands.bold,
          commands.italic,
          commands.divider,
          commands.title1,
          commands.title2,
          commands.title3,
          commands.divider,
          commands.orderedListCommand,
          commands.unorderedListCommand,
          commands.divider,
          commands.quote,
          commands.hr,
        ]}
        height="400"
      />
      <FormHelperText>{fieldState.error || " "}</FormHelperText>
    </FormControl>
  );
}
