import React from 'react';

import PrivateLayout from "layouts/material-private";
import { fetchMember, updateMember } from "apiRequest/member";
import { useHistory, useParams } from 'react-router-dom';
import useQuery from '@lottoasean/query-hook';
import useMutation from '@lottoasean/mutation-hook';
import MemberForm from 'components/forms/MemberForm';
import isEmpty from 'lodash.isempty';
import updateMemberValidation from 'formValidations/updateMember';
import FormContainer from 'components/FormContainer';

export default function MemberUpdate() {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, payload: member = { data: {} } } = useQuery(fetchMember, { id });
  const { loading: mutateLoading, error: mutateError, mutate } = useMutation(updateMember);

  const handleOnSubmit = async (data) => {
    const submitData = {
      ...data
    };

    const { error: mutateError } = await mutate({ id, data: submitData });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      history.push("/members");
    }

  };

  return (
    <PrivateLayout loading={loading} error={error}>
      {!isEmpty(member.data) &&
        <FormContainer
          initialValues={member.data}
          title="Edit Member"
          subheader={member.data.name}
          onSubmit={handleOnSubmit}
          validationSchema={updateMemberValidation}
          serverError={mutateError}
        >
          <MemberForm loading={mutateLoading} />
        </FormContainer>
      }
    </PrivateLayout >
  );
}
