import fetch from "@lottoasean/fetch-utils";

export const fetchTransactions = ({ params, ...rest }) => {
  return fetch({
    url: 'transactions',
    method: "GET",
    params,
    ...rest
  });
};

export const fetchTransactionsCount = ({ params, ...rest }) => {
  return fetch({
    url: 'transactions/count',
    method: "GET",
    params,
    ...rest
  });
};

export const fetchTransactionsCountByLotto = ({ params, ...rest }) => {
  return fetch({
    url: 'transactions/count/by_lotto',
    method: "GET",
    params,
    ...rest
  });
};

export const createTransaction = ({ data, ...rest }) => {
  return fetch({
    url: `transactions`,
    method: "POST",
    data,
    ...rest
  });
};
