import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PrivateLayout from "layouts/material-private";
import { fetchBetNumbers, fetchBetNumbersExports } from "apiRequest/betNumber";
import currency from '@lottoasean/currency-utils';
import useQuery from '@lottoasean/query-hook';
import useMutation from '@lottoasean/mutation-hook';
import ISTable from 'components/lSTable';
import { Grid, Link } from '@material-ui/core';
import TextInput from 'components/forms/fields/TextInput';
import SelectInput from 'components/forms/fields/SelectInput';
import datetime from '@lottoasean/datetime-utils';
import { fetchLottos } from 'apiRequest/lotto';

export default function BetNumberList() {

  const { loading, error, payload: betNumbers = { data: [] }, query } = useQuery(fetchBetNumbers, { params: { sort_by: { key: "id", order: "desc" }, partials: ['number', 'bet'] } });
  const { loading: mutateLoading, mutate } = useMutation(fetchBetNumbersExports);
  const { payload: lottos = { data: [] } } = useQuery(fetchLottos);
  const lottoOptions = lottos.data.map((lotto) => {
    return {
      value: lotto.id,
      label: lotto.title
    };
  });

  const handlePageChange = (page) => {
    query({ params: { page } });
  };

  const handleExportClick = async (period) => {
    const { error: mutateError, payload } = await mutate({ data: { period } });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      window.open(payload.data.link);
    }
  };

  const handleSearchSubmit = (search) => {
    query({ params: { filters: { search } } });
  };

  const hanldeFilterSubmit = (data) => {
    query({ params: { filters: data } });
  };

  const hanldeSortClick = (data) => {
    query({ params: { sort_by: data } });
  };

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.id,
      sort: true,
    },
    {
      key: 'created_at',
      label: 'Date',
      selector: row => datetime.format(row.created_at),
      sort: true,
    },
    {
      key: 'number',
      label: 'Number',
      selector: row => row.number?.number,
    },
    {
      key: 'multiply',
      label: 'Multiply',
      selector: row => row.multiply,
    },
    {
      key: 'unit',
      label: 'Unit',
      selector: row => currency.format(row.unit),
    },
    {
      key: 'win_rate',
      label: 'Win Rate',
      selector: row => currency.format(row.unit * row.multiply),
    },
    {
      key: 'bet.id',
      label: 'Bet ID',
      selector: row => <Link component={RouterLink} to={`/bets/${row.bet.id}`}>{row.bet.id}</Link>
    },
  ], []);

  return (
    <PrivateLayout loading={loading} error={error}>
      <ISTable
        title="Bet Numbers"
        loading={loading}
        columns={columns}
        data={betNumbers.data}
        pagination={true}
        count={betNumbers.total}
        page={betNumbers.current_page}
        rowsPerPage={betNumbers.per_page}
        onPageChange={handlePageChange}
        download={true}
        onDownloadClick={handleExportClick}
        downloadLoading={mutateLoading}
        filter={true}
        FilterFields={FilterFields}
        filterFieldsProps={{ lottoOptions }}
        filterFields={['unit', 'lotto_id', 'created_at_from', 'created_at_to']}
        filterSubmit={hanldeFilterSubmit}
        search={true}
        searchFields={['number']}
        searchSubmit={handleSearchSubmit}
        sortClick={hanldeSortClick}
      />
    </PrivateLayout>
  );
}

const FilterFields = ({ lottoOptions }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
      <Grid item xs={6}>
        <TextInput field="unit" size="small" margin="none" helperText={false} label="Unit" />
      </Grid>
      <Grid item xs={6}>
        <SelectInput field="lotto_id" label="Lotto" size="small" margin="none" helperText={false}
          options={lottoOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput type="date" field="created_at_from" size="small" margin="none" helperText={false} label="Date From" />
      </Grid>
      <Grid item xs={6}>
        <TextInput type="date" field="created_at_to" size="small" margin="none" helperText={false} label="Date To" />
      </Grid>
    </Grid>
  );
};
