import React from 'react';
import MDEditorTextarea from './fields/MDEditorTextarea';
import SubmitButton from './fields/SubmitButton';


export default function VarietyForm({ loading }) {
  return (
    <>
      <MDEditorTextarea field="rule" />
      <SubmitButton loading={loading} />
    </>
  );
}
