import React from 'react';
import { Avatar, Card, CardContent, Grid, Skeleton, Typography } from '@material-ui/core';
import { People as PeopleIcon } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';

export default function TotalMember({ loading, data }) {
  return (
    <Card>
      <CardContent>
        <Grid
          container sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              TOTAL MEMBERS
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {loading ? <Skeleton /> : data?.total}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: green[600],
                height: 56,
                width: 56
              }}
            >
              <PeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
