import React from 'react';

import PrivateLayout from "layouts/material-private";
import { useHistory, useParams } from 'react-router-dom';
import useQuery from '@lottoasean/query-hook';
import useMutation from '@lottoasean/mutation-hook';
import { bulkUpdateNumber } from 'apiRequest/number';
import NumberForm from 'components/forms/NumberForm';
import FormContainer from 'components/FormContainer';
import updateNumberValidation from "formValidations/updateNumber";
import { fetchVariety } from 'apiRequest/variety';

export default function VarietyNumberBulkUpdate() {
  const { varietyId } = useParams();

  const history = useHistory();
  const { loading, error, payload: variety = { data: {} } } = useQuery(fetchVariety, { id: varietyId, params: { partials: ['lotto'] } });
  const { loading: mutateLoading, error: mutateError, mutate } = useMutation(bulkUpdateNumber);

  const handleOnSubmit = async (data) => {

    if (!window.confirm(`This will update all numbers of ${variety.data.lotto?.title} ${variety.data?.title}. \n ARE YOU SURE?`)) {
      return;
    }
    const submitData = {
      ...data,
      variety_id: varietyId,
    };

    const { error: mutateError } = await mutate({ data: submitData });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      history.push(`/varieties/${varietyId}`);
    }
  };

  return (
    <PrivateLayout loading={loading} error={error}>
      <FormContainer
        title={"Edit Number Bulk"}
        subheader={`${variety.data.lotto?.title} ${variety.data?.title}`}
        onSubmit={handleOnSubmit}
        validationSchema={updateNumberValidation}
        serverError={mutateError}
      >
        <NumberForm loading={mutateLoading} />
      </FormContainer>

    </PrivateLayout >
  );
}
