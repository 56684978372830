import React from 'react';


import PrivateLayout from "layouts/material-private";
import { createRound } from "apiRequest/round";
import { useHistory, useParams } from 'react-router-dom';
import RoundForm from 'components/forms/RoundForm';
import useMutation from '@lottoasean/mutation-hook';
import createRoundValidation from 'formValidations/createRound';
import dateTime from '@lottoasean/datetime-utils';
import useQuery from '@lottoasean/query-hook';
import { fetchLotto } from 'apiRequest/lotto';
import FormContainer from 'components/FormContainer';

export default function LottoRoundCreate() {
  const { lottoId } = useParams();

  const history = useHistory();
  const { loading, error, payload: lotto } = useQuery(fetchLotto, { id: lottoId });
  const { loading: mutateLoading, error: mutateError, mutate } = useMutation(createRound);

  const handleOnSubmit = async (data) => {
    const submitData = {
      ...data,
      lotto_id: lottoId,
      opened_at: dateTime.format(data.opened_at, {
        type: "ISO"
      }),
      closed_at: dateTime.format(data.closed_at, {
        type: "ISO"
      })
    };

    const { error: mutateError } = await mutate({ data: submitData });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      history.push(`/lottos/${lottoId}/rounds`);
    }
  };

  return (
    <PrivateLayout loading={loading} error={error} >
      <FormContainer
        title="Add New Round"
        subheader={lotto?.data.title}
        onSubmit={handleOnSubmit}
        validationSchema={createRoundValidation}
        serverError={mutateError}
      >
        <RoundForm loading={mutateLoading} />
      </FormContainer>
    </PrivateLayout>
  );
};
