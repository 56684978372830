import { Box } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import React from 'react';

export default function SubmitButton({ children, loading, ...rest }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        mt: 2
      }}
    >
      <LoadingButton
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        {...rest}
      >
        Submit
      </LoadingButton>
    </Box>
  );
}
