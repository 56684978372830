import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader, Container, Divider } from '@material-ui/core';
import { Form, useFormApi } from 'informed';
import forEach from 'lodash.foreach';

export default function FormContainer({ maxWidth = "md", children, title, subheader, onSubmit, initialValues, validationSchema, serverError }) {
  return (
    <Container maxWidth={maxWidth}>
      <Card>
        <CardHeader
          title={title}
          subheader={subheader}
        />
        <Divider />
        <CardContent>
          <Form onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} onChange={(a)=>{console.log(a)}}>
            <Fields serverError={serverError} children={children} />
          </Form>
        </CardContent>
      </Card>
    </Container>
  );
}

const Fields = ({ serverError, children }) => {
  const formApi = useFormApi();

  useEffect(() => {
    if (serverError?.status === 422 && serverError.data.errors) {
      forEach(serverError.data.errors, (value, key) => {
        formApi.setError(key, value);
      });
    }
  }, [formApi, serverError]);

  return children;
};
