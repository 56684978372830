import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  colors,
  useTheme,
  Skeleton
} from '@material-ui/core';
import { useMemo } from 'react';

const ProfitByLotto = ({ loading, data: raw }) => {
  const theme = useTheme();

  const datasetsData = useMemo(() => {
    return raw?.reduce((p, { title, color, win, lost_bet }) => {
      const win_loss = lost_bet  - win;
      p.labels.push(title);
      p.backgroundColor.push(color);
      p.data.push(win_loss > 0 ? win_loss : 0);
      return p;
    }, {
      labels: [],
      backgroundColor: [
      ],
      data: [],
    });
  }, [raw]);

  const data = {
    datasets: [
      {
        data: datasetsData.data,
        backgroundColor: datasetsData.backgroundColor,
        borderColor: colors.common.black,
        hoverBorderColor: colors.common.black
      }
    ],
    labels: datasetsData.labels,
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card>
      <CardHeader title="Profit by Lotto" />
      <Divider />

      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          {loading ? <Skeleton><Doughnut /></Skeleton> :
            <Doughnut
              data={data}
              options={options}
            />
          }
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProfitByLotto;
