import fetch from "@lottoasean/fetch-utils";

export const fetchBetNumbers = ({ params, ...rest }) => {
  return fetch({
    url: 'bet_numbers',
    method: "GET",
    params,
    ...rest
  });
};

export const fetchBetNumber = ({ id, params, ...rest }) => {
  return fetch({
    url: `bet_numbers/${id}`,
    method: "GET",
    params,
    ...rest
  });
};

export const fetchBetNumbersExports = ({ id, params, ...rest }) => {
  return fetch({
    url: `bet_numbers/exports`,
    method: "POST",
    params,
    ...rest
  });
};
