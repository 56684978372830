import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PrivateLayout from "layouts/material-private";
import dateTime from '@lottoasean/datetime-utils';
import useQuery from '@lottoasean/query-hook';
import { fetchTransactions } from 'apiRequest/transaction';
import { Grid, Link } from '@material-ui/core';
import ISTable from 'components/lSTable';
import SelectInput from 'components/forms/fields/SelectInput';
import TextInput from 'components/forms/fields/TextInput';

export default function TransactionList() {

  const { loading, error, payload: transactions = { data: [] }, query } = useQuery(fetchTransactions, { params: { sort_by: { key: "id", order: "desc" }, partials: ['member'] } });

  const handlePageChange = (page) => {
    query({ params: { page } });
  };

  const hanldeSortClick = (data) => {
    query({ params: { sort_by: data } });
  };

  const hanldeFilterSubmit = (data) => {
    query({ params: { filters: data } });
  };


  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.id,
      sort: true
    },
    {
      key: 'date',
      label: 'Date',
      selector: row => dateTime.format(row.created_at),
    },
    {
      key: 'type',
      label: 'Type',
      selector: row => row.type,
    },
    {
      key: 'unit',
      label: 'Unit',
      selector: row => row.unit,
    },
    {
      key: 'member_name',
      label: 'Member Name',
      selector: row => <>{row.member?.name} (<Link component={RouterLink} to={`/members/${row.member?.id}/transactions`}>{row.member?.id}</Link>)</>
    },
  ], []);

  const typeOptions = [
    { value: "cash_in", label: "Cash In" },
    { value: "cash_out", label: "Cash out" },
    { value: "win", label: "Win" },
    { value: "bet", label: "Bet" },
    { value: "promotion", label: "Promotion" },
    { value: "return", label: "Return" },
    { value: "refund", label: "Refund" },
  ];

  return (
    <PrivateLayout loading={loading} error={error}>
      <ISTable
        title="Transactions"
        loading={loading}
        columns={columns}
        data={transactions.data}
        pagination
        count={transactions.total}
        page={transactions.current_page}
        rowsPerPage={transactions.per_page}
        onPageChange={handlePageChange}
        sortClick={hanldeSortClick}
        filter
        filterFields={['type', 'created_at_from', 'created_at_to']}
        FilterFields={FilterFields}
        filterFieldsProps={{ typeOptions }}
        filterSubmit={hanldeFilterSubmit}
      />
    </PrivateLayout>
  );
}

const FilterFields = ({ typeOptions }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} width="500px">
      <Grid item xs={12}>
        <SelectInput field="type" label="Type" size="small" margin="none" helperText={false}
          options={typeOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput type="date" field="created_at_from" size="small" margin="none" helperText={false} label="Date From" />
      </Grid>
      <Grid item xs={6}>
        <TextInput type="date" field="created_at_to" size="small" margin="none" helperText={false} label="Date To" />
      </Grid>
    </Grid>
  );
};
