import React from 'react';

import PrivateLayout from "layouts/material-private";
import { fetchRounds } from "apiRequest/round";
import { Link as RouterLink, useParams } from 'react-router-dom';
import useQuery from '@lottoasean/query-hook';

import dateTime from '@lottoasean/datetime-utils';
import ISTable from 'components/lSTable';
import { Button, Stack } from '@material-ui/core';
import TableButtonsContainer from 'components/TableButtonsContainer';

export default function LottoRoundList() {
  const { lottoId } = useParams();

  const { loading, error, payload: rounds = { data: [] }, query } = useQuery(fetchRounds, {
    params: { sort_by: { key: "id", order: "desc" }, filters: { lotto_id: lottoId }, partials: ['lotto'] }
  });

  const lotto = rounds.data[0]?.lotto;

  const handlePageChange = (page) => {
    query({ params: { page } });
  };

  const hanldeSortClick = (data) => {
    query({ params: { sort_by: data } });
  };

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.id,
      sort: true,
    },
    {
      key: 'title',
      label: 'Title',
      selector: row => row.title,
    },
    {
      key: 'opened_at',
      label: 'Opened at',
      selector: row => dateTime.format(row.opened_at),
    },
    {
      key: 'closed_at',
      label: 'Closed at',
      selector: row => dateTime.format(row.closed_at),
    },
    {
      key: 'actions',
      label: 'Actions',
      selector: row => {
        return (
          <Stack direction="row" spacing={2}>
            <Button size="small" variant="contained" component={RouterLink} className="button is-primary is-small" to={`/lottos/${lottoId}/rounds/${row.id}/update`}>Edit</Button>
            <Button size="small" variant="outlined" component={RouterLink} className="button is-info is-small" to={`/rounds/${row.id}/wins`}>Wins</Button>
          </Stack>
        );
      },
    },
  ], [lottoId]);

  return (
    <PrivateLayout loading={loading} error={error}>
      <TableButtonsContainer>
        <Button
          variant="contained"
          component={RouterLink}
          to={`/lottos/${lottoId}/rounds/create`}>
          Add New Round
        </Button>
      </TableButtonsContainer>
      <ISTable
        title="Rounds"
        subheader={lotto?.title}
        loading={loading}
        columns={columns}
        data={rounds.data}
        pagination={true}
        count={rounds.total}
        page={rounds.current_page}
        rowsPerPage={rounds.per_page}
        onPageChange={handlePageChange}
        sortClick={hanldeSortClick}
      />
    </PrivateLayout>
  );
}
