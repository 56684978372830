import React from 'react';
import { Box } from '@material-ui/core';

export default function PublicLayout({ children, error, loading }) {
  return (
    <Box>
      {children}
    </Box>
  );
}
