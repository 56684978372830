import React, { useEffect } from 'react';
import { Form } from 'informed';
import { useHistory } from 'react-router-dom';

import useToken from "@lottoasean/token-hook";
import useMutation from "@lottoasean/mutation-hook";
import { login } from "apiRequest/auth";
import PublicLayout from "layouts/material-public";
import TextInput from "components/forms/fields/TextInput";
import { Box, Card, CardContent, Container, Paper, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { LoginOutlined as LoginOutlinedIcon } from '@material-ui/icons';
import logo from 'logo.svg';
import { fetchUser } from 'apiRequest/user';

export default function Login() {
  const history = useHistory();
  const { setToken, token } = useToken();
  const { loading, mutate } = useMutation(login);
  const { mutate: query } = useMutation(fetchUser);

  useEffect(() => {
    if (token) {
      async function process() {
        const { error, payload } = await query();
        if (!error && payload?.data) {
          return history.push("/");
        }
      }

      process();
    }
  }, [token, query, history]);


  const handleSubmit = async ({ username, password }) => {
    const data = {
      username,
      password
    };

    const { payload, error } = await mutate({ data });

    if (error && error.status !== 400) {
      alert("Something was wrong!");
      return;
    }

    if (error && error.status === 400) {
      alert("Wrong Credential!");
      return;
    }

    setToken(payload.access_token, payload.expires_in);
    history.push('/');;
  };

  return (
    <PublicLayout>
      <Box sx={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}>
        <Container maxWidth="sm">
          <Box sx={{ textAlign: "center" }}>
            <img src={logo} height="100" alt="Lotto ASEAN" />
          </Box>
          <Card>
            <Paper sx={{ p: 2, textAlign: "center" }}>
              <Typography variant="h4">Login</Typography>
            </Paper>
            <CardContent>
              <Form onSubmit={handleSubmit} className="box" autoComplete="off">
                <TextInput field="username" label="Username" required />
                <TextInput field="password" label="Password" type="password" required />
                <LoadingButton
                  fullWidth
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<LoginOutlinedIcon />}
                >
                  Login
                </LoadingButton>
              </Form>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </PublicLayout >
  );
}
