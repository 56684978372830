import React from 'react';
import SubmitButton from './fields/SubmitButton';
import TextInput from './fields/TextInput';
import SelectInput from './fields/SelectInput';

export default function TransactionForm({ loading }) {
  return (
    <>
      <SelectInput field="type" label="Type"
        options={[
          { value: "cash_in", label: "Cash In" },
          { value: "cash_out", label: "Cash Out" },
          { value: "win", label: "Win" },
          { value: "promotion", label: "Promotion" },
          { value: "return", label: "Return" },
          { value: "refund", label: "Refund" },
        ]}
      />
      <TextInput field="unit" label="Unit" />
      <SubmitButton loading={loading} />
    </>
  );
}
