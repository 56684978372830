import React from 'react';

import PrivateLayout from "layouts/material-private";
import { fetchTransactions } from "apiRequest/transaction";
import { Link as RouterLink, useParams } from 'react-router-dom';
import useQuery from '@lottoasean/query-hook';

import dateTime from '@lottoasean/datetime-utils';
import currency from '@lottoasean/currency-utils';
import ISTable from 'components/lSTable';
import { Button } from '@material-ui/core';
import TableButtonsContainer from 'components/TableButtonsContainer';

export default function MemberTransactionList() {
  const { memberId } = useParams();

  const { loading, error, payload: transactions = { data: [] }, query } = useQuery(fetchTransactions, {
    params: { sort_by: { key: "id", order: "desc" }, filters: { member_id: memberId }, partials: ['member', 'admin'] }
  });

  const member = transactions.data[0]?.member;

  const handlePageChange = (page) => {
    query({ params: { page } });
  };

  const hanldeSortClick = (data) => {
    query({ params: { sort_by: data } });
  };


  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.id,
      sort: true
    },
    {
      key: 'unit',
      label: 'Unit',
      selector: row => currency.format(row.unit)
    },
    {
      key: 'type',
      label: 'Type',
      selector: row => row.type
    },
    {
      key: 'created_by',
      label: 'Created By',
      selector: row => row.admin?.name || "-"
    },
    {
      key: 'date',
      label: 'Date',
      selector: row => dateTime.format(row.created_at),
    }
  ], []);

  return (
    <PrivateLayout loading={loading} error={error}>
      <TableButtonsContainer maxWidth="md">
        <Button
          variant="contained"
          component={RouterLink}
          to={`/members/${memberId}/transactions/create`}
        >
          Add New Transaction
        </Button>
      </TableButtonsContainer>

      <ISTable
        maxWidth="md"
        title={member?.name}
        subheader={`Transactions, current unit: ${currency.format(member?.unit)}`}
        loading={loading}
        columns={columns}
        data={transactions.data}
        pagination={true}
        count={transactions.total}
        page={transactions.current_page}
        rowsPerPage={transactions.per_page}
        onPageChange={handlePageChange}
        sortClick={hanldeSortClick}
      />

    </PrivateLayout>
  );
}
