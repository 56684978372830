import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PrivateLayout from "layouts/material-private";
import { fetchMembers, verifyMember } from "apiRequest/member";
import dateTime from '@lottoasean/datetime-utils';
import currency from '@lottoasean/currency-utils';
import useQuery from '@lottoasean/query-hook';
import ISTable from 'components/lSTable';
import { Button, Grid } from '@material-ui/core';
import TableButtonsContainer from 'components/TableButtonsContainer';
import SelectInput from 'components/forms/fields/SelectInput';
import useMutation from '@lottoasean/mutation-hook';


export default function MemberList() {
  const { loading, error, payload: members = { data: [] }, query } = useQuery(fetchMembers, { params: { sort_by: { key: "id", order: "desc" } } });

  const { mutate } = useMutation(verifyMember);

  const handlePageChange = (page) => {
    query({ params: { page } });
  };

  const hanldeSortClick = (data) => {
    query({ params: { sort_by: data } });
  };

  const handleSearchSubmit = (search) => {
    query({ params: { filters: { search } } });
  };

  const hanldeFilterSubmit = (data) => {
    query({ params: { filters: data } });
  };

  const handleVerifyClick = async (id) => {

    if (!window.confirm('Are you sure you want to verify this member?')) {
      return;
    }

    const { error: mutateError } = await mutate({ id });

    if (mutateError) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      query();
    }
  };

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.id,
      sort: true,
    },
    {
      key: 'name',
      label: 'Name',
      selector: row => row.name,
    },
    {
      key: 'username',
      label: 'Username',
      selector: row => row.username,
    },
    {
      key: 'phone_number',
      label: 'Phone number',
      selector: row => row.phone_number,
    },
    {
      key: 'unit',
      label: 'Unit',
      selector: row => currency.format(row.unit),
    },
    {
      key: 'verified_at',
      label: 'Verified At',
      selector: row => (
        row.verified_at ? dateTime.format(row.verified_at) :
          <Button  disabled={loading} onClick={() => handleVerifyClick(row.id)}>
            Click to Verify
          </Button>
      ),
    },
    {
      key: 'created_at',
      label: 'Create At',
      selector: row => dateTime.format(row.created_at),
    },
    {
      key: 'actions',
      label: 'Actions',
      selector: row => (
        <>
          <Button component={RouterLink} to={`/members/${row.id}/transactions`}>Transactions</Button>
          <Button component={RouterLink} to={`/members/${row.id}/update`}>Edit</Button>
        </>
      )
    },

  ], []);

  return (
    <PrivateLayout loading={loading} error={error}>

      <TableButtonsContainer>
        <Button
          variant="contained"
          component={RouterLink}
          to={`/members/create`}
        >
          Add New Member
        </Button>
      </TableButtonsContainer>

      <ISTable
        title="Members"
        loading={loading}
        columns={columns}
        data={members.data}
        pagination={true}
        count={members.total}
        page={members.current_page}
        rowsPerPage={members.per_page}
        onPageChange={handlePageChange}
        search={true}
        searchFields={['name', 'username', 'phone number']}
        searchSubmit={handleSearchSubmit}
        filter
        filterSubmit={hanldeFilterSubmit}
        FilterFields={FilterFields}
        filterFields={['verified']}
        sortClick={hanldeSortClick}
      />
    </PrivateLayout>
  );
}

const FilterFields = () => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} width="500px">
      <Grid item xs={12}>
        <SelectInput field="verified" label="Verified" size="small" margin="none" helperText={false}
          options={[
            { value: true, label: 'Verified' },
            { value: false, label: 'Unverified' },
          ]}
        />
      </Grid>
    </Grid>
  );
};
