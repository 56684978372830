import { Container, Stack } from '@material-ui/core';
import React from 'react';

export default function TableButtonsContainer({ children, maxWidth = "none" }) {
  return (
    <Container sx={{ mb: 1 }} maxWidth={maxWidth}>
      <Stack direction="row" spacing={2}>
        {children}
      </Stack>
    </Container>
  );
}
