import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PrivateLayout from "layouts/material-private";
import { fetchVarieties } from "apiRequest/variety";
import useQuery from '@lottoasean/query-hook';
import ISTable from 'components/lSTable';
import { Link } from '@material-ui/core';

export default function VarietyList() {
  const { loading, error, payload: varieties = { data: [] } } = useQuery(fetchVarieties, { params: { partials: ['lotto'] } });

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => <Link component={RouterLink} to={`/varieties/${row.id}`}>{row.id}</Link>,
    },
    {
      key: 'title',
      label: 'Title',
      selector: row => `${row.lotto.title} ${row.title}`,
    },
  ], []);

  return (
    <PrivateLayout loading={loading} error={error}>
      <ISTable
        maxWidth="sm"
        size="medium"
        title="Varieties"
        loading={loading}
        columns={columns}
        data={varieties.data}
      />
    </PrivateLayout>
  );
}
