import fetch from "@lottoasean/fetch-utils";

export const fetchDailyTransactionReports = ({ params, ...rest }) => {
  return fetch({
    url: 'transactions/reports/daily',
    method: "GET",
    params,
    ...rest
  });
};
export const fetchMonthlyTransactionReports = ({ params, ...rest }) => {
  return fetch({
    url: 'transactions/reports/monthly',
    method: "GET",
    params,
    ...rest
  });
};
export const fetchYearlyTransactionReports = ({ params, ...rest }) => {
  return fetch({
    url: 'transactions/reports/yearly',
    method: "GET",
    params,
    ...rest
  });
};
