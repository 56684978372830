import React from 'react';
import SubmitButton from './fields/SubmitButton';
import TextInput from './fields/TextInput';

export default function RoundForm({ loading }) {
  return (
    <>
      <TextInput field="title" label="Title" />
      <TextInput type="datetime-local" field="opened_at" label="Opened at" />
      <TextInput type="datetime-local" field="closed_at" label="Closed at" />
      <SubmitButton loading={loading} />
    </>
  );
}
