import React from 'react';
import FieldErrorMessage from './FieldErrorMessage';
import SubmitButton from './fields/SubmitButton';
import TextInput from './fields/TextInput';

export default function WinForm({ loading, lock }) {
  return (
    <>
      <TextInput field="number" label="Number" />
      <FieldErrorMessage field="round_id" />
      <SubmitButton loading={loading} disabled={lock} />
    </>
  );
}
