import React from 'react';
import { Route, Switch } from "react-router-dom";

import PrivateRoute from 'components/PrivateRoute';

import Login from "pages/login";
import NotFound from "pages/404";
import BetList from "pages/bets/list";
import BetShow from "pages/bets/show";
import BetNumberList from "pages/bets-numbers/list";
import LottoList from "pages/lottos/list";
import LottoShow from "pages/lottos/show";
import LottoRoundList from "pages/lotto-rounds/list";
import LottoRoundUpdate from "pages/lotto-rounds/update";
import LottoRoundCreate from "pages/lotto-rounds/create";
import RoundWinList from "pages/round-wins/list";
import RoundVarietyWinCreate from "pages/round-variety-wins/create";
import VarietyList from "pages/varieties/list";
import VarietyShow from "pages/varieties/show";
import VarietyUpdate from "pages/varieties/update";
import VarietyNumberUpdate from "pages/variety-numbers/update";
import MemberList from "pages/members/list";
import MemberUpdate from "pages/members/update";
import MemberCreate from "pages/members/create";
import MemberTransactionList from "pages/member-transactions/list";
import MemberTransactionCreate from "pages/member-transactions/create";
import TransactionList from "pages/transactions/list";
import WinList from "pages/wins/list";
import TransactionReportDaily from 'pages/transaction-reports/daily';
import TransactionReportMonthly from 'pages/transaction-reports/monthly';
import TransactionReportYearly from 'pages/transaction-reports/yearly';
import Analytic from 'pages/analytics/analytic';
import VarietyNumberBulkUpdate from 'pages/variety-numbers/bulk-update';


export default function Routes() {
  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <PrivateRoute exact path="/bets">
        <BetList />
      </PrivateRoute>
      <PrivateRoute exact path="/bets/:id">
        <BetShow />
      </PrivateRoute>
      <PrivateRoute exact path="/bet-numbers">
        <BetNumberList />
      </PrivateRoute>
      <PrivateRoute exact path={["/lottos", "/"]}>
        <LottoList />
      </PrivateRoute>
      <PrivateRoute exact path="/lottos/:id">
        <LottoShow />
      </PrivateRoute>
      <PrivateRoute exact path="/lottos/:lottoId/rounds">
        <LottoRoundList />
      </PrivateRoute>
      <PrivateRoute exact path="/lottos/:lottoId/rounds/:roundId/update">
        <LottoRoundUpdate />
      </PrivateRoute>
      <PrivateRoute exact path="/lottos/:lottoId/rounds/create">
        <LottoRoundCreate />
      </PrivateRoute>
      <PrivateRoute exact path="/rounds/:roundId/wins">
        <RoundWinList />
      </PrivateRoute>
      <PrivateRoute exact path="/rounds/:roundId/varieties/:varietyId/wins/create">
        <RoundVarietyWinCreate />
      </PrivateRoute>
      <PrivateRoute exact path="/varieties">
        <VarietyList />
      </PrivateRoute>
      <PrivateRoute exact path="/varieties/:id">
        <VarietyShow />
      </PrivateRoute>
      <PrivateRoute exact path="/varieties/:id/update">
        <VarietyUpdate />
      </PrivateRoute>
      <PrivateRoute exact path="/varieties/:varietyId/numbers/:numberId/update">
        <VarietyNumberUpdate />
      </PrivateRoute>
      <PrivateRoute exact path="/varieties/:varietyId/numbers/bulk-update">
        <VarietyNumberBulkUpdate />
      </PrivateRoute>
      <PrivateRoute exact path="/members">
        <MemberList />
      </PrivateRoute>
      <PrivateRoute exact path="/members/:id/update">
        <MemberUpdate />
      </PrivateRoute>
      <PrivateRoute exact path="/members/create">
        <MemberCreate />
      </PrivateRoute>
      <PrivateRoute exact path="/members/:memberId/transactions">
        <MemberTransactionList />
      </PrivateRoute>
      <PrivateRoute exact path="/members/:memberId/transactions/create">
        <MemberTransactionCreate />
      </PrivateRoute>
      <PrivateRoute exact path="/transactions">
        <TransactionList />
      </PrivateRoute>
      <PrivateRoute exact path="/wins">
        <WinList />
      </PrivateRoute>
      <PrivateRoute exact path="/reports/transactions/daily">
        <TransactionReportDaily />
      </PrivateRoute>
      <PrivateRoute exact path="/reports/transactions/monthly">
        <TransactionReportMonthly />
      </PrivateRoute>
      <PrivateRoute exact path="/reports/transactions/yearly">
        <TransactionReportYearly />
      </PrivateRoute>
      <PrivateRoute exact path="/analytics">
        <Analytic />
      </PrivateRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
