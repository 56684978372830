import React from 'react';
import { useField } from 'informed';
import { TextField } from '@material-ui/core';

export default function TextInput(props) {

  const { fieldState, fieldApi, render, ref, userProps } = useField(props);

  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, ...rest } = userProps;
  return render(
    <React.Fragment>
      <TextField
        margin="dense"
        fullWidth
        error={Boolean(fieldState.error)}
        helperText={fieldState.error || " "}
        {...rest}
        ref={ref}
        value={!value && value !== 0 ? '' : value}
        onChange={e => {
          setValue(e.target.value);
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={e => {
          setTouched(true);
          if (onBlur) {
            onBlur(e);
          }
        }}
      />
    </React.Fragment>
  );
};
