import fetch from "@lottoasean/fetch-utils";

export const fetchRounds = ({ params, ...rest }) => {
  return fetch({
    url: 'rounds',
    method: "GET",
    params,
    ...rest
  });
};

export const fetchRound = ({ id, params, ...rest }) => {
  return fetch({
    url: `rounds/${id}`,
    method: "GET",
    params,
    ...rest
  });
};

export const fetchRoundsCount = ({ params, ...rest }) => {
  return fetch({
    url: 'rounds/count',
    method: "GET",
    params,
    ...rest
  });
};


export const updateRound = ({ id, data, ...rest }) => {
  return fetch({
    url: `rounds/${id}`,
    method: "PUT",
    data,
    ...rest
  });
};

export const createRound = ({ data, ...rest }) => {
  return fetch({
    url: `rounds`,
    method: "POST",
    data,
    ...rest
  });
};
