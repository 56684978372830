import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import {
  BrowserRouter,
} from "react-router-dom";

import Routes from 'Routes';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}
