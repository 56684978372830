import React from 'react';


import PrivateLayout from "layouts/material-private";
import { useHistory, useParams } from 'react-router-dom';
import useMutation from '@lottoasean/mutation-hook';
import createWinValidation from 'formValidations/createWin';
import { createWin } from 'apiRequest/wins';
import { fetchRound } from 'apiRequest/round';
import useQuery from '@lottoasean/query-hook';
import { fetchVariety } from 'apiRequest/variety';
import WinForm from 'components/forms/WinForm';
import FormContainer from 'components/FormContainer';
import { Typography } from '@material-ui/core';

export default function RoundWinCreate() {
  const { roundId, varietyId } = useParams();

  const history = useHistory();
  const { loading: roundLoading, error: roundError, payload: round = { data: {} } } = useQuery(fetchRound, { id: roundId, params: { partials: ['wins'] } });
  const { loading: varietyLoading, error: varietyError, payload: variety = { data: {} } } = useQuery(fetchVariety, { id: varietyId, params: { partials: ['lotto'] } });

  const { loading: mutateLoading, error: mutateError, mutate } = useMutation(createWin);

  const handleOnSubmit = async (data) => {
    const submitData = {
      ...data,
      round_id: roundId,
      variety_id: varietyId,
    };

    const { error: mutateError } = await mutate({ data: submitData });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    // error of submitting active round
    if (mutateError?.status === 422 && mutateError.data.errors) {
      if (mutateError.data.errors.round_id) {
        alert(mutateError.data.errors.round_id);
      }
    }

    if (!mutateError) {
      history.push(`/rounds/${roundId}/wins`);
    }
  };

  let winExists = false;
  // check win if win already exists for this round and variety
  if (round.data.wins?.filter(({ variety_id }) => variety_id === variety.data.id)[0]) {
    winExists = true;
  }

  return (
    <PrivateLayout error={roundError || varietyError} loading={roundLoading || varietyLoading}>
      {winExists ? (
        <Typography color="warning" variant="h4"> Win for this variety already exists.</Typography>
      ) : (
        <FormContainer
          title="Add Win Number"
          subheader={`${variety.data.lotto?.title} ${variety.data.title} - (${round.data.title}) ${round.data.is_active ? "(Active)" : ""}`}
          onSubmit={handleOnSubmit}
          validationSchema={createWinValidation({ numberDigit: variety.data.digit })}
          serverError={mutateError}
        >
          <WinForm loading={mutateLoading} lock={round.data.is_active} />
        </FormContainer>
      )}
    </PrivateLayout >
  );
};
