import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import PrivateLayout from "layouts/material-private";
import useQuery from '@lottoasean/query-hook';
import ISTable from 'components/lSTable';
import { Button } from '@material-ui/core';
import TableButtonsContainer from 'components/TableButtonsContainer';
import capitalize from 'lodash.capitalize';

export default function TransactionReportPageContainer({ fetch }) {
  const { loading, error, payload: transactions = { data: [] }, query } = useQuery(fetch, { params: { sort_by: { key: "date", order: "desc" } } });

  const handlePageChange = (page) => {
    query({ params: { page } });
  };

  const hanldeSortClick = (data) => {
    query({ params: { sort_by: data } });
  };

  const columns = React.useMemo(() => [
    {
      key: 'date',
      label: 'Date',
      selector: row => row.date,
      sort: true
    },
    {
      key: 'member_count',
      label: 'Member Count',
      selector: row => row.member_count,
    },
    {
      key: 'cash_in',
      label: 'Cash in',
      selector: row => row.cash_in,
      currency: true
    },
    {
      key: 'cash_out',
      label: 'Cash out',
      selector: row => row.cash_out,
      currency: true
    },
    {
      key: 'balance',
      label: 'Balance',
      selector: row => row.cash_in - row.cash_out,
      highlight: true,
      currency: true
    },
    {
      key: 'win_loss',
      label: 'Win/Loss',
      selector: row => row.lost_bet - row.win,
      highlight: true,
      currency: true
    },
    {
      key: 'win',
      label: 'Win',
      selector: row => row.win,
      currency: true
    },
    {
      key: 'bet',
      label: 'Bet',
      selector: row => row.bet,
      currency: true
    },
    {
      key: 'lost_bet',
      label: 'Lost bet',
      selector: row => row.lost_bet,
      currency: true
    },
  ], []);

  const location = useLocation();
  const current = location.pathname.split("/").pop();

  return (
    <PrivateLayout loading={loading} error={error}>
      <TableButtonsContainer>
        <RangeButtons current={current} />
      </TableButtonsContainer>
      <ISTable
        rowKey="date"
        title={`${capitalize(current)} Transactions`}
        loading={loading}
        columns={columns}
        data={transactions.data}
        pagination
        count={transactions.total}
        page={transactions.current_page}
        rowsPerPage={transactions.per_page}
        onPageChange={handlePageChange}
        sortClick={hanldeSortClick}
        defaultSort={{ key: 'date', 'order': 'desc' }}
      />
    </PrivateLayout>
  );
}

const RangeButtons = ({ current }) => {
  return ['daily', 'monthly', 'yearly'].map((range) => {
    return (
      <Button
        disabled={current === range}
        key={range}
        variant="contained"
        component={RouterLink}
        to={`/reports/transactions/${range}`}>
        {range.toLocaleUpperCase()}
      </Button>
    );
  });
};
