import fetch from "@lottoasean/fetch-utils";

export const fetchLottos = ({ params, ...rest }) => {
  return fetch({
    url: 'lottos',
    method: "GET",
    params,
    ...rest
  });
};

export const fetchLotto = ({ id, params, ...rest }) => {
  return fetch({
    url: `lottos/${id}`,
    method: "GET",
    params,
    ...rest
  });
};
