import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PrivateLayout from "layouts/material-private";
import { fetchBets } from "apiRequest/bet";
import dateTime from '@lottoasean/datetime-utils';
import currency from '@lottoasean/currency-utils';
import sumBy from 'lodash.sumby';
import useQuery from '@lottoasean/query-hook';
import ISTable from 'components/lSTable';
import { Grid, Link } from '@material-ui/core';
import SelectInput from 'components/forms/fields/SelectInput';
import { fetchVarieties } from 'apiRequest/variety';
import { fetchLottos } from 'apiRequest/lotto';

export default function BetList() {

  const { loading, error, payload: bets = { data: [] }, query } = useQuery(fetchBets, { params: { sort_by: { key: "id", order: "desc" }, partials: ['member', 'bet_numbers', 'variety', 'variety.lotto', 'round'] } });

  const { payload: varieties = { data: [] } } = useQuery(fetchVarieties, { params: { partials: ['lotto'] } });
  const { payload: lottos = { data: [] } } = useQuery(fetchLottos);

  const lottoOptions = lottos.data.map((lotto) => {
    return {
      value: lotto.id,
      label: lotto.title
    };
  });

  const varietyOptions = varieties.data.map((variety) => {
    return {
      value: variety.id,
      label: `${variety.lotto.title} ${variety.title}`
    };
  });


  const handlePageChange = (page) => {
    query({ params: { page } });
  };

  const hanldeFilterSubmit = (data) => {
    query({ params: { filters: data } });
  };

  const hanldeSortClick = (data) => {
    query({ params: { sort_by: data } });
  };

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => <Link component={RouterLink} to={`/bets/${row.id}`}>{row.id}</Link>,
      sort: true,
    },
    {
      key: 'member.name',
      label: 'Member Name',
      selector: row => row.member?.name
    },
    {
      key: 'created_at',
      label: 'Date',
      selector: row => dateTime.format(row.created_at),
      sort: true,
    },
    {
      key: 'variety.title',
      label: 'Variety',
      selector: row => <>{row.variety.lotto?.title} {row.variety.title}</>,
    },
    {
      key: 'title',
      label: 'Round',
      selector: row => row.round.title,
    },
    {
      key: 'total_amount',
      label: 'Total Amount',
      selector: row => currency.format(sumBy(row.bet_numbers, (b) => b.unit)),
    },
  ], []);

  return (
    <PrivateLayout loading={loading} error={error}>
      <ISTable
        title="Bets"
        loading={loading}
        columns={columns}
        data={bets.data}
        pagination={true}
        count={bets.total}
        page={bets.current_page}
        rowsPerPage={bets.per_page}
        onPageChange={handlePageChange}
        filter
        filterSubmit={hanldeFilterSubmit}
        FilterFields={FilterFields}
        filterFieldsProps={{ lottoOptions, varietyOptions }}
        filterFields={['lotto_id', 'variety_id']}
        sortClick={hanldeSortClick}
      />
    </PrivateLayout>
  );
}

const FilterFields = ({ lottoOptions, varietyOptions }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} width="500px">
      <Grid item xs={6}>
        <SelectInput field="lotto_id" label="Lotto" size="small" margin="none" helperText={false}
          options={lottoOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput field="variety_id" label="Variety" size="small" margin="none" helperText={false}
          options={varietyOptions}
        />
      </Grid>
    </Grid>
  );
};
