import React from 'react';
import PrivateLayout from "layouts/material-private";
import { Container, Grid } from '@material-ui/core';
import ProfitByLotto from 'components/analytics/ProfitByLotto';
import TotalMember from 'components/analytics/TotalMember';
import TotalProfit from 'components/analytics/TotalProfit';
import TotalRound from 'components/analytics/TotalRound';
import { fetchMembersCount } from 'apiRequest/member';
import useQuery from '@lottoasean/query-hook';
import { fetchRoundsCount } from 'apiRequest/round';
import { fetchTransactionsCount, fetchTransactionsCountByLotto } from 'apiRequest/transaction';
import TotalBet from 'components/analytics/TotalBet';
import { fetchBetsCount } from 'apiRequest/bet';


export default function Analytic() {

  const { loading: transactionsCountLoading, payload: transactionsCount = { data: {} } } = useQuery(fetchTransactionsCount);
  const { loading: membersCountLoading, payload: membersCount = { data: {} } } = useQuery(fetchMembersCount);
  const { loading: roundsCountLoading, payload: roundsCount = { data: {} } } = useQuery(fetchRoundsCount);
  const { loading: betsCountLoading, payload: betsCount = { data: {} } } = useQuery(fetchBetsCount);
  const { loading: transactionsCountByLottoLoading, payload: transactionsCountByLotto = { data: [] } } = useQuery(fetchTransactionsCountByLotto);

  return (
    <PrivateLayout>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3}>
            <TotalProfit loading={transactionsCountLoading} data={transactionsCount.data} />
          </Grid>
          <Grid item lg={3}>
            <TotalMember loading={membersCountLoading} data={membersCount.data} />
          </Grid>
          <Grid item lg={3}>
            <TotalRound loading={roundsCountLoading} data={roundsCount.data} />
          </Grid>
          <Grid item lg={3}>
            <TotalBet loading={betsCountLoading} data={betsCount.data} />
          </Grid>
          <Grid item lg={3}>
            <ProfitByLotto loading={transactionsCountByLottoLoading} data={transactionsCountByLotto.data} />
          </Grid>
        </Grid>
      </Container>
    </PrivateLayout>
  );
}
