import React, { useCallback } from 'react';

import PrivateLayout from "layouts/material-private";
import { createWinPay, fetchWins } from "apiRequest/wins";
import { Link as RouterLink, useParams } from 'react-router-dom';
import useQuery from '@lottoasean/query-hook';

import dateTime from '@lottoasean/datetime-utils';
import { fetchRound } from 'apiRequest/round';
import findIndex from "lodash.findindex";
import useMutation from '@lottoasean/mutation-hook';
import ISTable from 'components/lSTable';
import { Button, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Payment as PaymentIcon } from '@material-ui/icons';

export default function RoundWinList() {
  const { roundId } = useParams();

  const { loading, error, payload: wins = { data: [] }, query: winsQuery } = useQuery(fetchWins, {
    params: { filters: { round_id: roundId }, partials: ['number', 'variety'] }
  });

  const { loading: roundLoading, error: roundError, payload: round = { data: {} } } = useQuery(fetchRound, {
    id: roundId,
    params: { partials: ['lotto', 'lotto.varieties'] }
  });

  const { loading: mutateLoading, mutate } = useMutation(createWinPay);

  const handlePaySubmit = useCallback(
    (win) => async () => {

      if (!win) {
        return;
      }

      if (!window.confirm("Are You Sure?")) {
        return;
      }

      const { error: mutateError } = await mutate({ id: win.id });

      if (mutateError && mutateError.status !== 422) {
        alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
        console.error(mutateError);
      }

      if (!mutateError) {
        winsQuery();
      }
    },
    [mutate, winsQuery],
  );

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.win ? row.win.id : '-',
    },
    {
      key: 'variety',
      label: 'Variety',
      selector: row => row.title,
    },
    {
      key: 'number',
      label: 'Number',
      selector: row => row.win ? row.win.number.number : '-',
    },
    {
      key: 'multiply',
      label: 'Multiply',
      selector: row => row.win ? row.win.number.multiply : '-',
    },
    {
      key: 'created_at',
      label: 'Created at',
      selector: row => row.win ? dateTime.format(row.win.created_at) : '-',
    },
    {
      key: 'paid_at',
      label: 'Paid at',
      selector: row => row.win ? dateTime.format(row.win.paid_at) || "-" : "-"
    },
    {
      key: 'actions',
      label: 'Actions',
      selector: row => {
        const preventAddWin = !!row.win;
        const preventPay = !row.win || row.win?.is_paid || row.win?.is_processing;

        return (
          <Stack direction="row" spacing={2}>
            <Button variant="contained" disabled={preventAddWin} component={RouterLink} to={preventAddWin ? "#" : `/rounds/${roundId}/varieties/${row.id}/wins/create`} >
              Add Win
            </Button>
            <LoadingButton onClick={handlePaySubmit(row.win)} disabled={preventPay || mutateLoading} loading={row.win?.is_processing === true} loadingPosition="start" variant="contained" startIcon={<PaymentIcon />}>
              {row.win?.is_processing ? "Paying" : row.win?.paid_at === null ? "Pay" : "Paid"}
            </LoadingButton>
          </Stack>
        );
      },
    },
  ], [mutateLoading, roundId, handlePaySubmit]);

  const data = React.useMemo(() => (
    round?.data.lotto?.varieties.map((variety) => {
      const winIndex = findIndex(wins.data, { variety_id: variety.id });
      const win = wins.data[winIndex];
      const newData = { ...variety, win };
      return newData;
    })
  ), [round?.data.lotto?.varieties, wins.data]);

  return (
    <PrivateLayout loading={loading || roundLoading} error={error || roundError}>
      <ISTable
        maxWidth="lg"
        title="Wins"
        subheader={`${round.data.lotto?.title} - (${round.data?.title})`}
        loading={loading}
        columns={columns}
        data={data}
      />
    </PrivateLayout>
  );
}
