import React from 'react';

import PrivateLayout from "layouts/material-private";
import { fetchRound, updateRound } from "apiRequest/round";
import { useHistory, useParams } from 'react-router-dom';
import useQuery from '@lottoasean/query-hook';
import useMutation from '@lottoasean/mutation-hook';
import RoundForm from 'components/forms/RoundForm';
import isEmpty from 'lodash.isempty';
import updateRoundValidation from 'formValidations/updateRound';
import dateTime from '@lottoasean/datetime-utils';
import FormContainer from 'components/FormContainer';

export default function LottoRoundUpdate() {
  const { lottoId, roundId, } = useParams();

  const history = useHistory();
  const { loading, error, payload: round = { data: {} } } = useQuery(fetchRound, { id: roundId, params: { partials: ['lotto'] } });
  const { loading: mutateLoading, error: mutateError, mutate } = useMutation(updateRound);

  const handleOnSubmit = async (data) => {
    const submitData = {
      ...data,
      lotto_id: lottoId,
      opened_at: dateTime.format(data.opened_at, {
        type: "ISO"
      }),
      closed_at: dateTime.format(data.closed_at, {
        type: "ISO"
      })
    };

    const { error: mutateError } = await mutate({ id: roundId, data: submitData });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      history.push(`/lottos/${lottoId}/rounds`);
    }
  };

  return (
    <PrivateLayout loading={loading} error={error}>
      {!isEmpty(round.data) &&
        <FormContainer
          initialValues={{
            ...round.data,
            opened_at: dateTime.format(round.data.opened_at, {
              type: "DOMString"
            }),
            closed_at: dateTime.format(round.data.closed_at, {
              type: "DOMString"
            })
          }}
          title="Edit Round"
          subheader={`${round.data.lotto?.title} - ${round.data.title}`}
          onSubmit={handleOnSubmit}
          validationSchema={updateRoundValidation}
          serverError={mutateError}
        >
          <RoundForm loading={mutateLoading} />
        </FormContainer>
      }
    </PrivateLayout>
  );
}
