import React from 'react';
import TextInput from './fields/TextInput';
import SubmitButton from './fields/SubmitButton';

export default function NumberForm({ loading }) {
  return (
    <>
      <TextInput type="number" field="multiply" label="Multiply" />
      <TextInput field="maximum" label="Maximum" />
      <SubmitButton loading={loading} />
    </>
  );
}
