import { Box, Collapse, Divider, Drawer, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { fetchUser } from 'apiRequest/user';
import React from 'react';
import { NavLink } from 'react-router-dom';
import useQuery from '@lottoasean/query-hook';
import { ExpandLess as ExpandLessIcon } from '@material-ui/icons';

const menus = [
  {
    'text': 'Analytics',
    'to': '/analytics',
  },
  {
    'text': 'Lottos',
    'to': '/lottos',
  },
  {
    'text': 'Bets',
    'to': '/bets',
  },
  {
    'text': 'Bet Numbers',
    'to': '/bet-numbers',
  },
  {
    'text': 'Member',
    'to': '/members',
  },
  {
    'text': 'Transactions',
    'to': '/transactions',
  },
  {
    'text': 'Wins (unpaid)',
    'to': '/wins',
  },
  {
    'type': 'parent',
    'text': 'Reports',
  },
  {
    'type': 'children',
    'menus': [
      {
        'text': 'Transactions',
        'to': '/reports/transactions/daily',
      },
    ]
  }
];

export default function Sidebar() {
  const { payload: user = {} } = useQuery(fetchUser);

  return (
    <Drawer
      open
      variant="persistent"
      PaperProps={{
        style: {
          top: 64,
          height: 'calc(100% - 64px)',
          width: '256px'
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        component="nav"
        height="100%"
      >
        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            {user.data?.name || user?.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Admin
          </Typography>
        </Box>
        <Divider />

        <Box p={2}>
          <List component="nav">
            {menus.map(({ type, text, to, menus }) => {
              if (type === 'children') {
                return (
                  <Collapse key={`child_of_${text}`} in={true} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding >
                      {menus.map(({ text, to }) => {
                        return (
                          <ListItem sx={{ pl: 4 }} key={to} activeClassName="Mui-selected" button component={NavLink} to={to}>
                            <ListItemText>{text}</ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                );
              }
              if (type === 'parent') {
                return (
                  <ListItem key={text} button>
                    <ListItemText>{text}</ListItemText>
                    <ExpandLessIcon />
                  </ListItem>
                );
              }
              return (
                <ListItem key={to} activeClassName="Mui-selected" button component={NavLink} to={to}>
                  <ListItemText>{text}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Divider />
        <Box textAlign="center" p={2}>
          <Typography fontSize="small">
            Lotto ASEAN Adamin App 0.5
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
}
