import fetch from "@lottoasean/fetch-utils";

export const fetchBets = ({ params, ...rest }) => {
  return fetch({
    url: 'bets',
    method: "GET",
    params,
    ...rest
  });
};

export const fetchBet = ({ id, params, ...rest }) => {
  return fetch({
    url: `bets/${id}`,
    method: "GET",
    params,
    ...rest
  });
};

export const fetchBetsCount = ({ params, ...rest }) => {
  return fetch({
    url: 'bets/count',
    method: "GET",
    params,
    ...rest
  });
};
