import React from 'react';
import { Download as DownloadIcon } from '@material-ui/icons';

import {  IconButton, MenuItem, Popover, MenuList } from '@material-ui/core';


export default function TableDownload({ onDownloadClick, downloadLoading }) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (period) => () => {
    handleClose();
    onDownloadClick(period);
  };

  return (
    <>
      <IconButton onClick={handleClick} disabled={downloadLoading}>
        <DownloadIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
          <MenuItem onClick={handleDownload("all")}>All</MenuItem>
          <MenuItem onClick={handleDownload("today")}>Today</MenuItem>
          <MenuItem onClick={handleDownload("this_month")}>This Month</MenuItem>
          <MenuItem onClick={handleDownload("this_year")}>This Year</MenuItem>
        </MenuList>
      </Popover>
    </>
  );
}
