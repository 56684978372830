import fetch from "@lottoasean/fetch-utils";

export const fetchMembers = ({ params, ...rest }) => {
  return fetch({
    url: 'members',
    method: "GET",
    params,
    ...rest
  });
};

export const fetchMember = ({ id, params, ...rest }) => {
  return fetch({
    url: `members/${id}`,
    method: "GET",
    params,
    ...rest
  });
};

export const fetchMembersCount = ({ params, ...rest }) => {
  return fetch({
    url: 'members/count',
    method: "GET",
    params,
    ...rest
  });
};

export const updateMember = ({ id, data, ...rest }) => {
  return fetch({
    url: `members/${id}`,
    method: "PUT",
    data,
    ...rest
  });
};

export const createMember = ({ data, ...rest }) => {
  return fetch({
    url: `members`,
    method: "POST",
    data,
    ...rest
  });
};

export const verifyMember = ({ id, data, ...rest }) => {
  return fetch({
    url: `members/${id}/verify`,
    method: "PUT",
    ...rest
  });
};
