import { Alert, Box, LinearProgress} from '@material-ui/core';
import React from 'react';
import Sidebar from './Sidebar';
import NavBar from "./NavBar";
import { Redirect } from 'react-router-dom';
import useToken from '@lottoasean/token-hook';


export default function PrivateLayout({ children, error, loading }) {
  const { removeToken } = useToken();

  if (error?.status === 401) {
    removeToken();
    return <Redirect to="/login" />;
  }

  return (
    <Box>
      <NavBar />
      <Box top="60px" position="absolute" width="100%" zIndex="1300">
        {loading && <LinearProgress />}
      </Box>
      <Box display='flex'>
        <Sidebar />
        <Box component="main" ml="256px" p={4} mt="64px" flex="1">
          <Box position="absolute" right="0" minWidth="100px">
            {error && error?.status !== 404 && <Alert severity="error">
              {error?.message || "unexpected error!"}
            </Alert>}
          </Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
}
