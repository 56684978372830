import React from 'react';

import PrivateLayout from "layouts/material-private";
import { fetchNumbers } from "apiRequest/number";
import { Link as RouterLink, useParams } from 'react-router-dom';
import useQuery from '@lottoasean/query-hook';
import ISTable from 'components/lSTable';
import { Button } from '@material-ui/core';
import TableButtonsContainer from 'components/TableButtonsContainer';

export default function VarietyShow() {
  const { id: varietyId } = useParams();

  const { loading, error, payload: numbers = { data: [] }, query } = useQuery(fetchNumbers, {
    params: { filters: { variety_id: varietyId }, partials: ['variety', 'variety.lotto'] }
  });

  const variety = numbers.data[0]?.variety;

  const handlePageChange = (page) => {
    query({ params: { page } });
  };

  const handleSearchSubmit = (search) => {
    query({ params: { filters: { search } } });
  };

  const hanldeSortClick = (data) => {
    query({ params: { sort_by: data } });
  };

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.id,
      sort: true,
    },
    {
      key: 'number',
      label: 'Number',
      selector: row => row.number,
    },
    {
      key: 'multiply',
      label: 'Multiply',
      selector: row => row.multiply,
    },
    {
      key: 'maximum',
      label: 'Maximum',
      selector: row => row.maximum || "-",
    },
    {
      key: 'actions',
      label: 'ACTIONS',
      selector: row => <Button size="small" variant="contained" component={RouterLink} to={`/varieties/${variety.id}/numbers/${row.id}/update`} className="button is-small is-primary">Update</Button>,
    },

  ], [variety?.id]);

  return (

    <PrivateLayout loading={loading} error={error}>
      <TableButtonsContainer maxWidth="lg">
        <Button
          variant="contained"
          component={RouterLink}
          to={`/varieties/${varietyId}/numbers/bulk-update`}>
          Bulk Update
        </Button>
      </TableButtonsContainer>
      <ISTable
        maxWidth="lg"
        title="Numbers"
        subheader={`${variety?.lotto.title} ${variety?.title}`}
        loading={loading}
        columns={columns}
        data={numbers.data}
        pagination={true}
        count={numbers.total}
        page={numbers.current_page}
        rowsPerPage={numbers.per_page}
        onPageChange={handlePageChange}
        search={true}
        searchFields={['number']}
        searchSubmit={handleSearchSubmit}
        sortClick={hanldeSortClick}
      />
    </PrivateLayout >
  );
}
