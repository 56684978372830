import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);

const createMemberValidation = Yup.object().shape({
  name: Yup.string().required(),
  username: Yup.string().min(3).strict().lowercase().matches(/^[a-z,0-9]+$/, 'The username must only contain letters and numbers.').required(),
  phone_number: Yup.string().min(9).required(),
  password: Yup.string().min(8).password()
});

export default createMemberValidation;
