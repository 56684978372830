import fetch from "@lottoasean/fetch-utils";

export const fetchWins = ({ params, ...rest }) => {
  return fetch({
    url: 'wins',
    method: "GET",
    params,
    ...rest
  });
};

export const fetchWin = ({ id, params, ...rest }) => {
  return fetch({
    url: `wins/${id}`,
    method: "GET",
    params,
    ...rest
  });
};

export const updateWin = ({ id, data, ...rest }) => {
  return fetch({
    url: `wins/${id}`,
    method: "PUT",
    data,
    ...rest
  });
};

export const createWin = ({ data, ...rest }) => {
  return fetch({
    url: `wins`,
    method: "POST",
    data,
    ...rest
  });
};

export const createWinPay = ({ id, data, ...rest }) => {
  return fetch({
    url: `wins/${id}/pay`,
    method: "POST",
    data,
    ...rest
  });
};
