import fetch from "@lottoasean/fetch-utils";

export const fetchNumbers = ({ params, ...rest }) => {
  return fetch({
    url: 'numbers',
    method: "GET",
    params,
    ...rest
  });
};

export const fetchNumber = ({ id, params, ...rest }) => {
  return fetch({
    url: `numbers/${id}`,
    method: "GET",
    params,
    ...rest
  });
};

export const updateNumber = ({ id, data, ...rest }) => {
  return fetch({
    url: `numbers/${id}`,
    method: "PUT",
    data,
    ...rest
  });
};

export const bulkUpdateNumber = ({ data, ...rest }) => {
  return fetch({
    url: 'numbers',
    method: "PUT",
    data,
    ...rest
  });
};
