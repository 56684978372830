import React from 'react';
import PrivateLayout from "layouts/material-private";
import { createTransaction } from "apiRequest/transaction";
import { useHistory, useParams } from 'react-router-dom';
import TransactionForm from 'components/forms/TransactionForm';
import useMutation from '@lottoasean/mutation-hook';
import useQuery from '@lottoasean/query-hook';
import createTransactionValidation from 'formValidations/createTransaction';
import { fetchMember } from 'apiRequest/member';
import FormContainer from 'components/FormContainer';

export default function MemberTransactionCreate() {
  const { memberId } = useParams();

  const history = useHistory();

  const { loading, error, payload: member } = useQuery(fetchMember, { id: memberId });
  const { loading: mutateLoading, error: mutateError, mutate } = useMutation(createTransaction);

  const handleOnSubmit = async (data) => {
    const submitData = {
      ...data,
      member_id: memberId
    };

    const { error: mutateError } = await mutate({ data: submitData });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      history.push(`/members/${memberId}/transactions`);
    }
  };

  return (
    <PrivateLayout loading={loading} error={error}>
      <FormContainer
        title="Add New Transaction"
        subheader={member?.data.name}
        onSubmit={handleOnSubmit}
        validationSchema={createTransactionValidation}
        serverError={mutateError}
      >
        <TransactionForm loading={mutateLoading} />
      </FormContainer>
    </PrivateLayout>
  );
};
