import React from 'react';

import PrivateLayout from "layouts/material-private";
import { fetchLotto } from "apiRequest/lotto";
import { Link as RouterLink, useParams } from 'react-router-dom';
import useQuery from '@lottoasean/query-hook';
import { Button,  Link } from '@material-ui/core';
import ISTable from 'components/lSTable';
import TableButtonsContainer from 'components/TableButtonsContainer';

export default function LottoShow() {
  const { id } = useParams();

  const { loading, error, payload: lotto = { data: {} } } = useQuery(fetchLotto, { id, params: { partials: ['varieties'] } });

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => <Link component={RouterLink} to={`/varieties/${row.id}`}>{row.id}</Link>
    },
    {
      key: 'title',
      label: 'Title',
      selector: row => row.title,
    },
    {
      key: 'action',
      label: 'Action',
      selector: row => <Button component={RouterLink} to={`/varieties/${row.id}/update`}>Edit</Button>,
    }
  ], []);

  return (
    <PrivateLayout loading={loading} error={error}>

      <TableButtonsContainer maxWidth="md">
        <Button
          variant="contained"
          component={RouterLink}
          to={`/lottos/${lotto.data.id}/rounds`}
        >
          Rounds
        </Button>
      </TableButtonsContainer>

      <ISTable
        title={lotto.data.title}
        subheader="Varieties"
        maxWidth="md"
        loading={loading}
        columns={columns}
        data={lotto.data.varieties}
        size="medium"
      />

    </PrivateLayout>
  );
}
