import React from 'react';

import PrivateLayout from "layouts/material-private";
import { createMember } from "apiRequest/member";
import { useHistory } from 'react-router-dom';
import MemberForm from 'components/forms/MemberForm';
import useMutation from '@lottoasean/mutation-hook';
import createMemberValidation from 'formValidations/createMember';
import FormContainer from 'components/FormContainer';

export default function MemberCreate() {
  const history = useHistory();
  const { loading: mutateLoading, error: mutateError, mutate } = useMutation(createMember);

  const handleOnSubmit = async (data) => {
    const submitData = {
      ...data
    };
    const { error: mutateError } = await mutate({ data: submitData });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      history.push("/members");
    }
  };

  return (
    <PrivateLayout>
      <FormContainer
        title="Member"
        subheader="Add New Member"
        onSubmit={handleOnSubmit}
        validationSchema={createMemberValidation}
        serverError={mutateError}
      >
        <MemberForm loading={mutateLoading} />
      </FormContainer>
    </PrivateLayout>
  );
};
